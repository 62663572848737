import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const MaterialProjectSelect = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(true);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setMenuOpen(false);
  };

  return (
    <React.Fragment>
      <button className='text-[#3D9123] flex items-center' onClick={openMenu}>
        <span className="text-lg lg:text-2xl">
          {props.selectedProjectName}
        </span>
        <ArrowDropDownIcon className={`mt-1 ${menuOpen ? 'rotate-180' : ''} transition-transform duration-300`} />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={closeMenu}
        className='custom-dropdown'
        PaperProps={{
          style: {
            top: '0',
            left: '50%',
            transform: 'translateY(10%)',
            padding: '0.5rem',
            height: '60svh',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
            borderRadius: '8px'
          }
        }}
      >
        {props.projects
          ? props.projects.map((project) => (
            <MenuItem
              key={project.id}
              value={project.id}
              onClick={() => {
                setMenuOpen(false);
                props.handleChange(project.name, project.id);
              }}
            >
              {project.name}
            </MenuItem>
          ))
          : null}
      </Menu>
    </React.Fragment>
  );
};

export default MaterialProjectSelect;
