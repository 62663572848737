import React, { useEffect, useState } from 'react';
import MaterialTable from './MaterialTable/MaterialTable';
import Dialog from '@material-ui/core/Dialog';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ConversationDetail from './ConversationDetail/ConversationDetail';
import Slide from '@material-ui/core/Slide';
import AutocompleatableSelect from '../Select/AutocompleatableSelect';
import CoachingKangaroo from '../../api/CoachingKangaroo';
import TrainingToolTheme from '../TrainingToolTheme';
import ConversationStatusSelect from '../ConversationStatusSelect/ConversationStatusSelect';
import ConversationFeelingSelect from '../ConversationFeelingSelect/ConversationFeelingSelect';
import { ratingCodes } from './ConversationItem/AnswerRatingButtons/AnswerRatingButtons';
import TextField from '@material-ui/core/TextField';
import {
    formatDateToYyyyMmDd,
    TIME_FROM,
    TIME_TO
} from '../../utils/formattingUtils';
import useAlert from '../hooks/useAlert';
import RangeSelect, { EDGES } from '../Select/RangeSelect';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import { Clear } from '@material-ui/icons';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import parse from 'html-react-parser';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';

const statuses = [
    'NOT_RATED',
    'RATED',
    'TRAINING_NEEDED',
    'TRAINED',
    'RESOLVED'
];

const aiFilters = {
    aiRated: 'AI Rated',
    aiNotRated: 'AI NOT Rated',
    noAiFilter: 'NO AI Filter'
};

const feelings = ['NONE', 'GOOD', 'BAD'];

const platforms = [
    'WEB_PUBLIC',
    'MOBILE_APP',
    'IM',
    'PHONE',
    'GOOGLE_ASSISTANT'
];

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MaterialTrainingTool = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [conversationPreview, setConversationPreview] = useState(null);
    const [dirtyConversation, setDirtyConversation] = useState(null);
    const [inputSessionID, setInputSessionID] = useState('');
    const [dateFrom, setDateFrom] = useState(() => {
        const d = new Date();
        d.setDate(d.getDate() - 1);
        return d;
    });
    const [dateTo, setDateTo] = useState(new Date());
    const [intents, setIntents] = useState([]);
    const [entities, setEntities] = useState([]);
    const [context, setContext] = useState(props.context);
    const [contextValues, setContextValues] = useState([]);
    const [entityValues, setEntityValues] = useState([]);
    const [selectedIntents, setSelectedIntents] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]);
    const [selectedEntityValues, setSelectedEntityValues] = useState([]);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [selectedFeelings, setSelectedFeelings] = useState([]);
    const [selectedRatings, setSelectedRatings] = useState([]);
    const [selectedPlatforms, setSelectedPlatforms] = useState([]);
    const [selectedContext, setSelectedContext] = useState([]);
    const [selectedContextValues, setSelectedContextValues] = useState([]);
    const [filter, setFilter] = useState(null);
    const [scoreFrom, setScoreFrom] = useState(EDGES.min);
    const [scoreTo, setScoreTo] = useState(EDGES.max);
    const [fulltext, setFulltext] = useState('');
    const [aiFilter, setAiFilter] = useState(aiFilters.noAiFilter);
    const { Alert, status, showError } = useAlert();
    const { project, projectId } = props;
    const { t } = useTranslation();
    const [aiDialog, setAiDialog] = useState(false);
    const [aiReport, setAiReport] = useState('');

    const fetchReport = (project, atFrom, atTo) => {
        const url = `/api/training/v1/${encodeURIComponent(project)}/report?at_from=${formatDateToYyyyMmDd(atFrom)}${TIME_FROM}&at_to=${formatDateToYyyyMmDd(atTo)}${TIME_TO}`;
        CoachingKangaroo.get(url)
            .then((response) => {
                setAiReport(response.data);
            })
            .catch((e) => {
                console.log(e);
                setAiReport('');
                setAiDialog(false);
                showError(t('Report unavailable'));
            });
    };

    useEffect(() => {
        getEntities();
        getIntents();
        filterSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectId]);

    useEffect(() => {
        setSelectedContext([]);
        setSelectedContextValues([]);
        setSelectedIntents([]);
        setSelectedEntities([]);
        setSelectedEntityValues([]);
        getEntities();
        getIntents();
        getContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, dateTo, projectId]);

    useEffect(() => {
        if (selectedEntities.length > 0) {
            CoachingKangaroo.get(
                '/api/training/v1/' +
                encodeURIComponent(projectId) +
                '/entity-values?entities=' +
                selectedEntities +
                '&at_from=' +
                formatDateToYyyyMmDd(dateFrom) +
                TIME_FROM +
                '&at_to=' +
                formatDateToYyyyMmDd(dateTo) +
                TIME_TO
            )
                .then((response) => {
                    setEntityValues(response.data);
                })
                .catch((error) => {
                    console.error('Error while fetching data', error);
                });
        } else {
            setEntityValues([]);
            setSelectedEntityValues([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEntities]);

    useEffect(() => {
        if (selectedContext.length > 0) {
            CoachingKangaroo.get(
                '/api/training/v1/' +
                encodeURIComponent(projectId) +
                '/context-values?context=' +
                selectedContext +
                '&at_from=' +
                formatDateToYyyyMmDd(dateFrom) +
                TIME_FROM +
                '&at_to=' +
                formatDateToYyyyMmDd(dateTo) +
                TIME_TO
            )
                .then((response) => {
                    setContextValues(response.data);
                })
                .catch((error) => {
                    console.error('Error while fetching data', error);
                });
        } else {
            setContextValues([]);
            setSelectedContextValues([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedContext]);

    useEffect(() => {
        function handleEscapeKey(event) {
            if (event.code === 'Escape') {
                handleDialogClose();
            }
        }

        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, []);

    const rowClicked = (row) => {
        setConversationPreview(row);
        setDialogOpen(true);
    };

    const getContext = () => {
        CoachingKangaroo.get(
            '/api/training/v1/' +
            encodeURIComponent(projectId) +
            '/context' +
            '?at_from=' +
            formatDateToYyyyMmDd(dateFrom) +
            TIME_FROM +
            '&at_to=' +
            formatDateToYyyyMmDd(dateTo) +
            TIME_TO
        )
            .then((response) => {
                const result = response.data;
                if (result) {
                    setContext(result);
                }
            })
            .catch((error) => {
                showError('Error while fetching context', error);
                setContext([]);
            });
    };

    const getIntents = () => {
        CoachingKangaroo.get(
            '/api/training/v1/' +
            encodeURIComponent(projectId) +
            '/intents' +
            '?at_from=' +
            formatDateToYyyyMmDd(dateFrom) +
            TIME_FROM +
            '&at_to=' +
            formatDateToYyyyMmDd(dateTo) +
            TIME_TO
        )
            .then((response) => {
                setIntents(response.data);
            })
            .catch((error) => {
                setIntents([]);
                console.error('Error while fetching data', error);
            });
    };

    const getEntities = () => {
        CoachingKangaroo.get(
            '/api/training/v1/' +
            encodeURIComponent(projectId) +
            '/entities' +
            '?at_from=' +
            formatDateToYyyyMmDd(dateFrom) +
            TIME_FROM +
            '&at_to=' +
            formatDateToYyyyMmDd(dateTo) +
            TIME_TO
        )
            .then((response) => {
                const result = response.data;
                if (result) {
                    setEntities(result);
                }
            })
            .catch((error) => {
                setEntities([]);
                console.error('Error while fetching data', error);
            });
    };

    const filterSubmit = () => {
        setFilter({
            dateFrom: dateFrom,
            dateTo: dateTo,
            selectedIntents: selectedIntents,
            selectedEntities: selectedEntities,
            selectedEntityValues: selectedEntityValues,
            selectedStatuses: selectedStatuses,
            selectedFeelings: selectedFeelings,
            selectedRatings: selectedRatings,
            inputSessionID: inputSessionID,
            selectedPlatforms: selectedPlatforms,
            selectedContext: selectedContext,
            selectedContextValues: selectedContextValues,
            scoreFrom: scoreFrom / 100,
            scoreTo: scoreTo / 100,
            fulltext: fulltext,
            aiFilter: aiFilter
        });
    };

    const filterReset = () => {
        setDateFrom(null);
        setDateTo(null);
        setSelectedIntents([]);
        setSelectedEntities([]);
        setSelectedEntityValues([]);
        setSelectedStatuses([]);
        setSelectedFeelings([]);
        setSelectedRatings([]);
        setInputSessionID('');
        setSelectedPlatforms([]);
        setSelectedContext([]);
        setSelectedContextValues([]);
        setScoreFrom(EDGES.min);
        setScoreTo(EDGES.max);
        setFulltext('');
        setAiFilter(aiFilters.noAiFilter);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleSessionIDChange = (event) => {
        setInputSessionID(event.target.value.trim());
    };

    return (
        <div className="flex flex-col lg:bg-[#f3f3f3] lg:p-8 rounded-lg">
            {status && <Alert severity="info">{status}</Alert>}
            <MuiThemeProvider theme={TrainingToolTheme}>
                <div
                    className="flex w-full justify-between items-start flex-col lg:flex-row lg:items-center mb-4 z-40 border-b border-[#d9d9d9] pb-4">
                    <h3 className="mb-0 text-xl font-normal">{t('Filters')}</h3>
                    <div className="mt-2 lg:mt-0 flex flex-col lg:flex-row gap-4 lg:items-center">
                        <button
                            className="py-1.5 px-3 bg-[#58a813] rounded-lg font-normal text-base hover:bg-[#4f9711] transition-all"
                            onClick={filterSubmit}
                        >
                            {t('Filter')}
                        </button>
                        <button
                            className="py-1.5 px-3 bg-[#e0e0e0] rounded-lg font-normal text-base hover:bg-[#cacaca] transition-all"
                            onClick={filterReset}
                        >
                            {t('Reset')}
                        </button>
                        <button
                            className="flex items-center gap-1 bg-white rounded-lg pl-1.5 pr-1.5 lg:pr-3"
                            onClick={() => {
                                setAiReport('');
                                fetchReport(projectId, dateFrom, dateTo);
                                setAiDialog(true);
                            }}
                        >
                            <img
                                width={36}
                                alt={'ai_avatar'}
                                src={'./ai_avatar.png'}
                            />
                            <span className="font-normal">{t('Report')}</span>
                        </button>
                    </div>
                </div>

                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                label={t('Date From')}
                                value={dateFrom}
                                onChange={setDateFrom}
                                clearable
                                disableFuture
                                format="dd.MM.yyyy"
                                autoOk
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <KeyboardDatePicker
                                fullWidth
                                label={t('Date To')}
                                value={dateTo}
                                onChange={setDateTo}
                                clearable
                                disableFuture
                                format="dd.MM.yyyy"
                                autoOk
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Platforms')}
                            options={platforms}
                            selectedOptions={selectedPlatforms}
                            setSelectedOptions={setSelectedPlatforms}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ConversationStatusSelect
                            statuses={statuses}
                            selectedStatuses={selectedStatuses}
                            setSelectedStatuses={setSelectedStatuses}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <ConversationFeelingSelect
                            feelings={feelings}
                            selectedFeelings={selectedFeelings}
                            setSelectedFeelings={setSelectedFeelings}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label={t('Session ID')}
                            fullWidth
                            onChange={handleSessionIDChange}
                            value={inputSessionID}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        style={{ visibility: inputSessionID ? 'visible' : 'hidden' }}
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => setInputSessionID('')}
                                    >
                                        <Clear />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Answer rating')}
                            options={ratingCodes.map((it) => it.code)}
                            selectedOptions={selectedRatings}
                            setSelectedOptions={setSelectedRatings}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Entities')}
                            options={entities}
                            selectedOptions={selectedEntities}
                            setSelectedOptions={setSelectedEntities}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Entity values')}
                            noOptionsText={t('empty_entities')}
                            options={entityValues}
                            selectedOptions={selectedEntityValues}
                            setSelectedOptions={setSelectedEntityValues}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Intents')}
                            options={intents}
                            selectedOptions={selectedIntents}
                            setSelectedOptions={setSelectedIntents}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <RangeSelect
                            valueFrom={scoreFrom}
                            valueTo={scoreTo}
                            setValueFrom={setScoreFrom}
                            setValueTo={setScoreTo}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Context')}
                            options={context}
                            selectedOptions={selectedContext}
                            setSelectedOptions={setSelectedContext}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <AutocompleatableSelect
                            label={t('Context values')}
                            options={contextValues}
                            noOptionsText={t('empty_context')}
                            selectedOptions={selectedContextValues}
                            setSelectedOptions={setSelectedContextValues}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            label={t('Search')}
                            fullWidth
                            onChange={(e) => setFulltext(e.target.value)}
                            value={fulltext}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        style={{ visibility: fulltext ? 'visible' : 'hidden' }}
                                        aria-label="delete"
                                        size="small"
                                        onClick={() => setFulltext('')}
                                    >
                                        <Clear />
                                    </IconButton>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Select
                            fullWidth
                            value={aiFilter}
                            onChange={(e) => setAiFilter(e.target.value)}
                        >
                            <MenuItem value={aiFilters.aiRated}>{t(aiFilters.aiRated)}</MenuItem>
                            <MenuItem value={aiFilters.aiNotRated}>{t(aiFilters.aiNotRated)}</MenuItem>
                            <MenuItem value={aiFilters.noAiFilter}>{t(aiFilters.noAiFilter)}</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <div className="w-full custom-table mt-4">
                    <Grid container>
                        <Grid item xs={12}>
                            <MaterialTable
                                project={project}
                                projectId={projectId}
                                filter={filter}
                                dirtyConversation={dirtyConversation}
                                onClick={rowClicked}
                            />
                        </Grid>
                    </Grid>
                </div>

                <Dialog
                    open={dialogOpen}
                    onBackdropClick={handleDialogClose}
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="customized-dialog-title"
                >
                    <ConversationDetail
                        projectId={projectId}
                        project={project}
                        handleDialogClose={handleDialogClose}
                        conversationPreview={conversationPreview}
                        setDirtyConversation={setDirtyConversation}
                    />
                </Dialog>

                <Dialog
                    open={aiDialog}
                    onBackdropClick={() => setAiDialog(false)}
                    TransitionComponent={Transition}
                    fullWidth
                    maxWidth="lg"
                    aria-labelledby="customized-dialog-title"
                >
                    <div className="flex flex-col min-h-80 justify-start items-end">
                        <IconButton
                            aria-label="close"
                            onClick={() => setAiDialog(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                        {aiReport === '' ? (
                            <div className="flex justify-center items-center h-full m-auto">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>{parse(aiReport)}</div>
                        )}
                    </div>
                </Dialog>
            </MuiThemeProvider>
        </div>
    );


};

export default MaterialTrainingTool;
