import React, { useEffect, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import CoachingKangaroo from '../../../api/CoachingKangaroo';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import ConversationItem from '../ConversationItem/ConversationItem';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ConversationStatusButtons from './ConversationStatusButtons/ConversationStatusButtons';
import ConversationFeelingButtons from './ConversationFeelingButtons/ConversationFeelingButtons';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

const formatDate = (value) => {
  if (value) {
    return new Intl.DateTimeFormat('cs-CZ', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(Date.parse(value));
  } else {
    return '';
  }
};

const styles = (theme) => ({
  // root: {
  //     margin: 0,
  //     padding: theme.spacing(2),
  // },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ConversationDetail = (props) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setDataFetched(false);
    console.log('Conversation ID: ' + props.conversationPreview.id);

    CoachingKangaroo.get(
      '/api/training/v1/' +
        encodeURIComponent(props.projectId) +
        '/conversation/' +
        props.conversationPreview.id
    )
      .then((response) => {
        console.log(
          'Data fetched: ' + JSON.stringify(response.data).slice(0, 90) + '...'
        );
        setErrorMessage(null);
        setData(response.data);
        setDataFetched(true);
      })
      .catch((error) => {
        if (error.message) {
          setErrorMessage(error.message);
        } else {
          setErrorMessage('Unknown error');
        }
        setData(null);
        setDataFetched(true);
        console.error(error);
      });
  }, [props.conversationPreview, props.projectId]);

  return (
    <React.Fragment>
      {!dataFetched ? (
        <React.Fragment>
          <DialogTitle
            id="customized-dialog-title"
            onClose={props.handleDialogClose}
          >
            {t('Conversation Detail')}: ...
          </DialogTitle>
          <DialogContent className='flex flex-col'>
            <CircularProgress className='flex m-auto' />
          </DialogContent>
        </React.Fragment>
      ) : errorMessage ? (
        <React.Fragment>
          <DialogTitle
            id="customized-dialog-title"
            onClose={props.handleDialogClose}
          >
            {`${t('Conversation Detail')}: ${props.conversationPreview.text}`}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    {t('Platform')}: {props.conversationPreview.platform}
                  </Grid>
                  <Grid item>
                    {t('Session ID')}: {props.conversationPreview.id}
                  </Grid>
                  <Grid item>
                    {t('Date')}:{' '}
                    {formatDate(props.conversationPreview['started-at'])}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
            <Grid container>
              <Alert severity="error">Error: {errorMessage}</Alert>
            </Grid>
          </DialogContent>
        </React.Fragment>
      ) : (
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
        >
          <DialogTitle
            id="customized-dialog-title"
            onClose={props.handleDialogClose}
          >
            {`${t('Conversation Detail')}: ${props.conversationPreview.text}`}
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Grid container spacing={2}>
                  <Grid item>
                    {t('Platform')}: {props.conversationPreview.platform}
                  </Grid>
                  <Grid item>
                    {t('Session ID')}: {props.conversationPreview.id}
                  </Grid>
                  <Grid item>
                    {t('Date')}:{' '}
                    {formatDate(props.conversationPreview['started-at'])}
                  </Grid>
                  {props.conversationPreview.problemSolvedAI && (
                    <Grid item>
                      Problem status from AI:{' '}
                      {props.conversationPreview.problemSolvedAI
                        ? 'resolved'
                        : 'unresolved'}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid sx={{ textAlign: 'center' }} item>
                <ConversationFeelingButtons
                  project={props.projectId}
                  setDirtyConversation={props.setDirtyConversation}
                  conversationId={props.conversationPreview.id}
                  feeling={props.conversationPreview.feeling}
                  feelingAI={props.conversationPreview.feelingAI}
                />
              </Grid>
              <Grid item>
                <ConversationStatusButtons
                  project={props.projectId}
                  setDirtyConversation={props.setDirtyConversation}
                  conversationId={props.conversationPreview.id}
                  status={props.conversationPreview.status}
                />
              </Grid>
            </Grid>
            {data.map((conversationItem) => {
              return (
                <React.Fragment key={conversationItem.id}>
                  <Divider style={{ marginTop: '1em', marginBottom: '1em' }} />
                  <ConversationItem
                    project={props.projectId}
                    conversationId={props.conversationPreview.id}
                    inputType={conversationItem.inputType}
                    data={conversationItem}
                  />
                </React.Fragment>
              );
            })}
          </DialogContent>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ConversationDetail;
