import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PhoneIcon from '@material-ui/icons/PhoneOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';

const UserMenu = ({ user, selectedLanguage, handleLangSwitch, switchLoggedInState }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);

    const recordButtonPosition = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };
    useEffect(() => {
        const listener = (e) => {
            const elem = document.getElementById("user-menu");
            const composedPath = e.composedPath()
            let isTarget = true;
            composedPath.forEach((item) => {
                if (item === elem) {
                    isTarget = false;
                }
            })
            if (isTarget) {
                closeMenu()
            }
        }
        window.addEventListener("click", listener)
        return () => {
            window.removeEventListener("click", listener)
        }
    }, []);

    return (
        <div id={"user-menu"}>
            {user ? (
                <div
                    onClick={recordButtonPosition}
                    className="bg-[#ebebeb] hover:bg-[#dfdfdf] transition-all cursor-pointer w-9 h-9 text-center leading-[2.25rem] rounded-full"
                >
                    <span className="">
                        {user.firstname.slice(0, 1)}
                    </span>
                    <span className="">
                        {user.lastname.slice(0, 1)}
                    </span>
                    {anchorEl ? (
                        <Menu
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={closeMenu}
                            PaperProps={{
                                style: {
                                    transform: 'translateX(-4%) translateY(13%)',
                                    right: '0px',
                                    borderRadius: '8px',
                                    padding: '0.75rem',
                                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.1)',
                                },
                            }}
                        >
                            <div className="flex flex-col">
                                <div className="flex gap-2 border-b pb-4">
                                    <div
                                        className="w-16 h-16 text-center rounded-full border-[1.5px] border-[#3D9123] flex">
                                        <div className="flex m-auto ">
                                            <span className="text-3xl text-[#3D9123]">
                                                {user.firstname.slice(0, 1)}
                                            </span>
                                            <span className="text-3xl text-[#3D9123]">
                                                {user.lastname.slice(0, 1)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col pr-12">
                                        <div className="flex gap-0.5 items-center">
                                            <span className="font-medium mr-1">{user.firstname}</span>
                                            <span className="font-medium">{user.lastname}</span>
                                        </div>
                                        <span className="text-sm opacity-80">{user.email}</span>
                                    </div>
                                </div>
                                <div className="flex w-full justify-between items-center mt-4">
                                    <div className="flex text-sm gap-2">
                                        <div
                                            className={`cursor-pointer px-2 py-1 rounded-lg ${selectedLanguage === 'cz' ? 'bg-[#51c32e37]' : 'text-black'}`}
                                            onClick={() => handleLangSwitch('cz')}
                                        >
                                            CZ
                                        </div>
                                        <div
                                            className={`cursor-pointer px-2 py-1 rounded-lg ${selectedLanguage === 'en' ? 'bg-[#51c32e37]' : 'text-black'}`}
                                            onClick={() => handleLangSwitch('en')}
                                        >
                                            EN
                                        </div>
                                    </div>
                                    <button className="text-sm flex gap-2 items-center"
                                        onClick={switchLoggedInState}>
                                        <ExitToAppIcon width={18} height={18} className="opacity-80 p-0.5" />
                                        <span>Logout</span>
                                    </button>
                                </div>
                                <div className="mt-4 flex flex-col">
                                    <span className="text-xs mb-1.5">Do you need help?</span>
                                    <a href="tel:00420776116111"
                                        className="hover:text-[#3D9123] flex items-center gap-1 text-black text-xs py-0.5">
                                        <PhoneIcon width={18} height={18} className="opacity-80 p-1" />
                                        <span>+420 776 116 111</span>
                                    </a>
                                    <a href="mailto:info@addai.cz"
                                        className="hover:text-[#3D9123] flex items-center gap-1 text-black text-xs py-0.5">
                                        <MailIcon width={18} height={18} className="opacity-80 p-1" />
                                        <span>info@addai.cz</span>
                                    </a>
                                </div>
                            </div>
                        </Menu>
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default UserMenu;