import React, { useLayoutEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.scss';
import './Mobile.scss';
import MaterialTrainingTool from './components/MaterialTrainingTool/MaterialTrainingTool';
import MaterialProjectSelect from './components/MaterialProjectSelect/MaterialProjectSelect';
import packageInfo from '../package.json';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import User from './models/User';
import keycloak from './api/Keycloak';
import { useTranslation } from 'react-i18next';
import Header from './components/Header/Header';
import CoachingKangaroo from "./api/CoachingKangaroo";

const resfreshAccessTokenInterval = 1770; //s

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const App = () => {
  const [, setWindowWidth] = useState(0);
  const [, setWindowHeight] = useState(0);
  const [projects, setProjects] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState("")
  const [user, setUser] = useState(new User());
  const [loginError, setError] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const { i18n } = useTranslation();
  const classes = useStyles;

  const [selectedLanguage, setSelectedLanguage] = useState('cz');

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(resfreshAccessTokenInterval)
      .success((refreshed) => {
        if (refreshed) {
          setAccessToken(keycloak.token);
        } else {
          setError('Failed to refresh token');
        }
      })
      .error(() => {
        setError('Failed to refresh token');
      });
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', resizeWindow);
    resizeWindow();
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const handleOnEvent = async (event) => {
    if (event === 'onAuthSuccess') {
      if (keycloak.authenticated) {
        setAccessToken(keycloak.token);
        const profile = await keycloak.loadUserProfile();
        const context =
          keycloak.tokenParsed.context !== undefined
            ? keycloak.tokenParsed.context.split(';')
            : [];
        const clientRoles = keycloak.realmAccess.roles.filter(
          (role) => role.startsWith('ADMIN') || role.startsWith('TRAINING')
        );
        getProjects();
        if (isSingleMode()) {
          setSingleModeProject();
        } else {
          getProjects();
        }
        let keycloakUser = new User(
          profile.username,
          profile.firstName,
          profile.lastName,
          profile.email,
          clientRoles,
          context
        );
        setUser(keycloakUser);
      }
    }
  };

  const isSingleMode = () => {
    if (!window.g_viaSingleMode?.startsWith('%'))
      return Boolean(window.g_viaSingleMode) !== false; // it's a string
    else return process.env.REACT_APP_SINGLE_MODE === true;
  };

  const setSingleModeProject = () => {
    let projectId = !window.g_viaSingleModeProjectId?.startsWith('%')
      ? window.g_viaSingleModeProjectId
      : process.env.REACT_APP_PROJECT_ID;
    let projectName = !window.g_viaSingleModeProjectName?.startsWith('%')
      ? window.g_viaSingleModeProjectName
      : process.env.REACT_APP_PROJECT_NAME;

    setProjects([{ id: projectId, name: projectName }]);
    setProjectName(projectName);
  };

  const getProjects = () => {
    CoachingKangaroo.get('/api/v1/projects', {
      headers: {
        Authorization: 'Bearer ' + keycloak.token,
      },
    })
      .then((response) => {
        console.log(response)
        setProjects(response.data);
        setProjectId(response.data[0].id)
        setProjectName(response.data[0].name);
      })
      .catch(() => {
        setError('Error while fetching data');
      });
  };

  const switchLoggedInState = () => {
    setProjects([]);
    setProjectName('');
    setUser(null);
    keycloak.logout();
  };

  const handleLangSwitch = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const handleProjectChange = (name, id) => {
    setProjectId(id)
    setProjectName(name);
  };
  const { t } = useTranslation();

  return (
    <ReactKeycloakProvider
      className={classes.root}
      authClient={keycloak}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      <div className={'flex flex-col w-full App ' + (keycloak.authenticated ? 'loggedIn' : 'd')}>
        <Header
          user={user}
          selectedLanguage={selectedLanguage}
          handleLangSwitch={handleLangSwitch}
          switchLoggedInState={switchLoggedInState}
          loginError={loginError}
        />
        <div className="flex flex-col w-full py-3 px-3 2xl:max-w-[1400px] 2xl:mx-auto">
          {user ? (
            <>
              <div className="flex gap-1.5 items-center mb-9 mx-auto px-4 mt-3">
                <h2 className="font-light text-lg lg:text-2xl mb-0">{t('Training')}</h2>
                <MaterialProjectSelect
                  projects={projects}
                  selectedProjectName={projectName}
                  handleChange={handleProjectChange}
                />
              </div>
            </>
          ) : null}
          {projectName ? (
            <div>
              <MaterialTrainingTool
                accessToken={accessToken}
                project={projectName}
                projectId={projectId}
                context={user.context}
              />
            </div>
          ) : null}
        </div>

        <footer className="flex bg-[#e2e2e2] mt-9">
          <span className="block mx-auto py-1.5 opacity-80">Made by AddAI team. Version: {packageInfo.version}</span>
        </footer>
      </div>
    </ReactKeycloakProvider>
  );
};

export default App;
